const awsConfig = {
  Auth: {
    region: 'us-east-2',
    userPoolId: 'us-east-2_9oo8ixL48',
    userPoolWebClientId: '2tq35b07hd1o1evgs6v0q78d50',
  },
  API: {
    blumApiEndpoint: 'https://api.test.miblum.com',
  },
};

export default awsConfig;
